.custom-container {
    padding: 50px;
  }
  
  @media (min-width: 992px) {
    .custom-container .col-lg-6 {
      max-width: 400px; /* Adjust the max width here */
    }
  }
  
  .btnColor {
    background: linear-gradient(to right, rgb(55, 194, 194), rgb(37, 68, 207));
    border: none;
  }