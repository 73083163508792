/* EventCard.css */
.event-card {
  width: 100%;
  margin: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.event-card:hover {
  transform: translateY(-5px);
}

.event-card img {
  height: 200px;
  object-fit: cover;
}

.event-card .card-body {
  padding: 1rem;
}

.event-card .card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.event-card .card-text {
  color: #666;
  margin-bottom: 0.5rem;
}

.event-card .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.event-card .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.slider-card-container {
  margin: 0 10px; /* Adjust the margin as needed */
}

.event-list {
  display: flex;
  justify-content: center;
}